* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Asap', sans-serif;
}

a {
	font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
p,
a {
	margin: 0;
}
